@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: 'Rubik', sans-serif;
  padding: 0;
  margin: 0;
  font-size: 100%;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tenant-name-highlight {
  font-weight: bold;
  color: #007bff; /* Example styling */
}

span.recharts-legend-item-text {
  @apply text-gray-500 !important;
}

.loading-spinner {
  border: 6px solid;
  @apply border-gray-900/15 border-r-inforcer-cyan;
  animation: loading-animation 900ms linear infinite;
}

.toggle-button {
  padding: 10px 20px;
  background-color: #ddd;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.toggle-button.on {
  background-color: #4caf50;
  color: white;
}

.toggle-button.off {
  background-color: #f44336;
  color: white;
}

@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
